.star {
    position: absolute;
    font-size: 0.5vw;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    user-select: none; /* Standard */
    -webkit-touch-callout: none; /* iOS Safari */
    pointer-events: none; /* Disable mouse events */
    animation-name: moveStar;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.stars {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: black; 
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.stars.moving .star {
    animation-play-state: running;
}

@keyframes moveStar {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-110vw); /* Move off-screen */
    }
}