body {
    background-color: #000000;
    overflow: hidden;
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
}

a {
    color: #ffffff;
}

.socials {
    margin-top: 50px;
}

.socials a {
    color: #ffffff;
    font-size: 3.1em;
}

.mainContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10%;
}

.mainContent > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

p {
    font-size: 1.2rem;
    width: 40%;
    text-align: center;
}

.subtext {
    margin-top: 0px;
    font-size: 0.9rem;
}

/* styles for mobile devices */
@media only screen and (max-width: 768px) {
    p {
        font-size: 1.2rem;
        width: 80%;
        text-align: center;
    }
}

.legal-links {
    margin-top: 20px;
    font-size: 0.8rem;
}

.legal-links {
    color: #cccccc;
    text-decoration: none;
}

.legal-links a:hover {
    text-decoration: underline;
}

.svg-icon {
    filter: invert(1); /* This will turn a black SVG white */
  }